import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent implements OnInit {
  @Input() actualPage: number | string = 1
  @Input() pages: number | string = 1
  @Input() nextButtonLabel = "Próxima"
  @Input() previousButtonLabel = "Anterior"

  @Output() pageChange = new EventEmitter
  constructor() { }

  ngOnInit(): void {
  }


  onPageChange(isNext: boolean) {
    this.pageChange.emit({
      page: Number(this.actualPage),
      isNext: isNext
    })
  }

  onStartButtonCheck(): boolean {
    const actualPage = Number(this.actualPage)
    return actualPage === 1
  }

  onEndButtonCheck(): boolean {
    const actualPage = Number(this.actualPage)
    return actualPage === this.pages
  }

  getPaginatorPageText() {
    return (
      `${Number(this.actualPage || 1)} de ${this.pages || 1}`
    )
  }

}

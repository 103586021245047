import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePaginatorComponent } from './table-paginator.component';



@NgModule({
  declarations: [
    TablePaginatorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [TablePaginatorComponent]
})
export class TablePaginatorModule { }

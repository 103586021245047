<div class='row w-100 justify-content-lg-center justify-content-end mx-0 align-items-center'>
    <p class='col-auto page-label mb-0'>
        {{getPaginatorPageText()}}
    </p>
    <button class='btn-arrow' [disabled]="onStartButtonCheck()"  (click)="onPageChange(false)" >
        <i class="fa fa-chevron-left"></i>
    </button>
    <button class='btn-arrow ml-4' [disabled]="onEndButtonCheck()" (click)="onPageChange(true)" >
        <i class="fa fa-chevron-right"></i>
    </button>
</div>
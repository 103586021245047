import { Injectable } from '@angular/core';
import { MatchUnity } from 'src/app/model/match-unity.class';
import { MatchVacancy } from '../../model/math-vacancy.class';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class VacancyModelsService {

  constructor(private http: HttpClient) { }

  getVacancyModels(){
    return this.http.get<any>(`${environment.auth_url_api}vacancies/models`)
  }

  getVacancyModel(id_model: number) {
    return this.http.get<any>(`${environment.auth_url_api}vacancies/models/${id_model}`)
  }

  createVacancyModel(model: any) {
    return this.http.post<any>(`${environment.auth_url_api}vacancies/models`, {...model})
  }

}
